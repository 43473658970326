<template>
  <section class="hero">
    <div class="add-padding">
      <div class="row">
        <div class="col d-flex align-items-center">
          <div class="mobile-hero">
            <h1>
              <span class="span-background">
                Conseil
              </span>
              EN <br>
              RESSOURCES HUMAINES <br> &
              <span class="span-background">
                ACCOMPAGNEMENT
              </span><br>
              PROFESSIONNEL DEPUIS 1999
            </h1>
            <br>
            <h4 class="small_desc fw-600">EMCI accompagne les enjeux des entreprises, des collectivités et facilite les
              évolutions
              professionnelles des collaborateurs.
            </h4>
            <div class="box-button">
              <a class="btn-orange-outline" href="/organisme-formation">
                <i class="fa-light fa-chalkboard-user me-2"></i>Organisme de
                formation</a>
              <a class="btn-rouge-outline mobile-sm-button-mt" href="/bilan_competences">
                <i class="fa-light fa-chart-user me-2 me-2"></i>Bilan de
                compétences</a>
            </div>
          </div>
        </div>
        <div class="col box-image">
          <img class="hero-img circle" src="/images/Bandeaux/img-hero2-modified.png" alt="Conseil RH en accompagnement">
          <img class="animation-img triangle" src="/images/Bandeaux/elmnt-triangle.png" alt="Triangle">
          <img class="animation-img-delay-1 carre" src="/images/Bandeaux/elmnt-carre.png" alt="Triangle">
          <img class="animation-img-delay-2 rond" src="/images/Bandeaux/elmnt-cercle.png" alt="Triangle">
        </div>
      </div>
    </div>
  </section>
</template>


<script setup>
</script>

<style scoped>
.hero {

  overflow: hidden !important;
  margin-top: 40px;
  position: relative;
  font-family: "Inter";
}

.hero p {
  font-family: "Inter";
}

.fw-600 {
  font-weight: 600;
}

.btn-orange-outline,
.btn-rouge-outline {
  display: flex;
  padding: 1.25rem 2.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.0375rem;
  border-radius: 3.125rem;
  font-family: "Inter";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  border-width: 2px;
}

.small_desc {
  font-size: 1.313rem;
  line-height: normal;
}

.hero h1 {
  color: var(--accent, #25233E);
  /* h1 */
  font-family: Nunito;
  font-size: 3.688rem;
  font-style: normal;
  font-weight: 700;
  line-height: 111.5%;
  /* 65.785px */
  letter-spacing: -0.885px;
  text-transform: uppercase;
}

.hero .box-button {
  display: flex;
  gap: 1rem;
}

.box-image {
  position: relative;
  margin-right: -12rem !important;
}

.animation-img.triangle {
  position: absolute;
  bottom: 30px;
}

.animation-img-delay-1.carre {
  position: absolute;
  top: 0;
  right: 162px;
}

.animation-img-delay-2.rond {
  position: absolute;
  bottom: 173px;
  right: 69px;
}

.box-image .hero-img {
  max-height: 780px;
  margin-left: 3rem;
}

.box-button {
  white-space: nowrap;
}

.circle {
  border-radius: 40%;
}

@media screen and (min-width: 350px) and (max-width: 426px) {
  .animation-img-delay-2.rond {
    right: 40px !important;
  }
}

@media screen and (max-width: 599px) {
  .hero {
    margin-top: 90px;
  }

  .mobile-hero {
    width: 100%;
  }

  .hero-img {
    min-width: 250px;
    max-width: 250px;
    margin-left: unset !important;
  }

  .animation-img.triangle,
  .animation-img-delay-1.carre,
  .animation-img-delay-2.rond {
    width: 20%;
  }

  .animation-img-delay-1.carre {
    right: 35px;
  }

  .animation-img-delay-2.rond {
    right: 10px;
    bottom: 57px;
  }

  .hero h1 {
    text-align: left;
  }

  .hero .row {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.2rem !important;
  }

  .small_desc {
    font-size: 1.3125rem;
    font-size: 0.820rem;
    text-align: left;
    line-height: normal;
  }

  /* button */
  .box-button {
    display: flex;
    gap: 1px;
    width: 90%;
  }

  .box-button a {
    display: flex;
    align-items: center;
    padding: 8px 14px;
    font-size: 0.7em;
  }

  .box-button a i {
    font-size: 1.2em;
    margin-right: 5px;
  }

  .mobile-sm-button-mt {
    margin-top: 0px;
  }

  .box-button {
    display: flex;
    justify-content: space-around;
  }

}

@media screen and (min-width: 374px) and (max-width: 500px) {
  .animation-img-delay-1.carre {
    right: 30px !important;
  }

  .animation-img.triangle {
    left: 20px !important;
  }

  .animation-img-delay-2.rond {
    right: 48px !important;
    bottom: 0px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 769px) {
  .hero h1 {
    text-align: left;
  }
}

@media screen and (max-width: 769px) {
  .row {
    flex-direction: column-reverse;
  }

  .box-image {
    order: 1;
    margin-bottom: 20px;
  }

  .hero-img {
    width: 90%;
  }

  .hero {
    margin-top: 150px;
    margin-bottom: 50px;
  }

  .hero .row {
    display: flex;
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .box-image {
    margin-bottom: 20px;
  }


  .hero .row .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .hero .box-button {
    flex-direction: column;
  }

  .animation-img.triangle {
    left: 10px;
    width: 20%;
    top: 60%;
  }

  .animation-img.triangle,
  .animation-img-delay-1.carre,
  .animation-img-delay-2.rond {
    width: 20%;
  }

  .animation-img-delay-1.carre {
    right: 15px;
  }

  .animation-img-delay-2.rond {
    right: 8px;
    bottom: 26px;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {

  .row {
    flex-direction: column-reverse;
  }

  .box-image {
    order: 1;
    margin-bottom: 20px;
  }

  .hero-img {
    width: 90%;
  }

  .hero {
    margin-top: 150px;
    margin-bottom: 50px;
  }

  .hero .row {
    display: flex;
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .box-image {
    margin-bottom: 20px;
  }


  .hero .row .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .hero h1 {
    text-align: left;
  }

  .hero .box-button {
    flex-direction: column;
  }

  .animation-img.triangle {
    left: 10px;
    width: 20%;
    top: 60%;
  }

  .animation-img.triangle,
  .animation-img-delay-1.carre,
  .animation-img-delay-2.rond {
    width: 20%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1024px) {
  .box-image {
    position: relative;
    margin-right: -3.5rem !important;
  }

  .hero-img {
    margin-left: unset !important;
  }

  .small_desc {
    font-size: 1.18rem;
  }

  .box-button a {
    font-size: 80%;
    padding: 2% 1% 1% 3%;
  }

  .animation-img.triangle,
  .animation-img-delay-1.carre,
  .animation-img-delay-2.rond {
    width: 20%;
  }

  .animation-img.triangle {
    left: 0%;
    bottom: 15%;
  }

  .animation-img-delay-1.carre {
    top: 0%;
    left: 65%
  }

  .animation-img-delay-2.rond {
    bottom: 18%;
    right: -1%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1399px) {
  .box-image {
    position: relative;
    margin-right: -6rem !important;
  }

  .hero-img {
    margin-left: unset !important;
  }

  .small_desc {
    font-size: 1.18rem;
  }

  .box-button a {
    font-size: 80%;
    padding: 2% 3% 1% 3%;
  }

  .hero {
    margin-top: 120px;
  }

  .animation-img.triangle,
  .animation-img-delay-1.carre,
  .animation-img-delay-2.rond {
    width: 20%;
  }

  .animation-img.triangle {
    left: 0%;
    bottom: 15%;
  }

  .animation-img-delay-1.carre {
    top: 0%;
    left: 65%
  }

  .animation-img-delay-2.rond {
    bottom: 18%;
    right: -1%;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1750px) {
  h1 {
    font-size: 2.525rem !important;
  }

  .box-image .hero-img[data-v-f992991b] {
    max-height: 579px;
    margin-left: unset !important;
  }

  .animation-img.triangle,
  .animation-img-delay-1.carre,
  .animation-img-delay-2.rond {
    width: 20%;
  }

  p,
  .bloctitleText,
  a,
  a span,
  .small_desc {
    font-size: 0.913rem !important;
    line-height: normal;
  }
}

@media screen and (min-width: 320px) {
  h1 {
    font-size: 1.563rem !important;
  }
}

@media screen and (min-width: 375px) {
  h1 {
    font-size: 1.7rem !important;
  }
}

@media screen and (min-width: 425px) {
  h1 {
    font-size: 1.9rem !important;
  }
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 2.1rem !important;
  }

  .small_desc {
    font-size: 1.113rem !important;
    text-align: left;
  }

}

@media screen and (min-width: 1024px) {
  h1 {
    font-size: 2.3rem !important;
  }

  .small_desc {
    font-size: 1.213rem !important;
    text-align: left;
  }
}

@media screen and (min-width: 1440px) {
  h1 {
    font-size: 3.05rem !important;
  }

  .small_desc {
    font-size: 1.293rem !important;
    text-align: left;
  }
}

@media screen and (min-width: 2559px) {
  h1 {
    font-size: 3.5rem !important;
  }

  .small_desc {
    font-size: 1.313rem !important;
    text-align: left;
  }
}

@media (width: 2560px) {

  .box-image {
    margin-right: -56rem !important;
  }

  .animation-img-delay-1.carre {
    left: 666px !important;
  }

  .animation-img.triangle {
    left: 20px !important;
  }

  .animation-img-delay-2.rond {
    left: 650px !important;
    bottom: 53px !important;
  }
}
</style>