<template>
    <section class="Emci-Carte section-padding">
        <div class="add-padding">
            <div class="row columns">
                <div class="col carte-France p-relative desktop">
                    <img class="carte" src="/images/pages/accueil/carteee.png" alt="EMCI partout en France">
                    <div v-for="point in   points  " :key="point.id" class="box-point desktop">
                        <a href="" v-if="point.id < 17" onclick="event.preventDefault();">
                            <img :id="'point-' + point.id" class="point" :src="point.img" :alt="point.title" :style="{
                        position: 'absolute',
                        top: point.coordY + 'px',
                        left: point.coordX + 'px',
                    }">
                        </a>
                        <a href="" v-if="point.id > 16" onclick="event.preventDefault();">
                            <div :id="'point-' + point.id" class="point-12-city-arround" :style="{
                        position: 'absolute',
                        top: point.coordY + 'px',
                        left: point.coordX + 'px',
                        padding: '2px 10px'
                    }" v-show="displayFromPoint17">
                                {{ point.title }}
                            </div>
                        </a>
                    </div>
                </div>
                <div class="mb-3 mobile">
                    <div class="d-flex justify-content-center">
                        <nuxt-img src="/images/pages/carte/carte.png"></nuxt-img>
                    </div>
                </div>
                <div class="col carteTexte">
                    <h2 class="titre-section">EMCI en occitanie et <br>
                        <span class="span-background title">
                            partout en france
                        </span>
                    </h2>
                    <br>
                    <div class="body-text">
                        <p>Cabinet de conseil en Ressources Humaines & Accompagnement Professionnel, créé en Occitanie
                            en
                            1999.
                        </p>
                        <br>
                        <p>Nous proposons des prestations de conseil et d’accompagnement : recrutement, formation,
                            gestion
                            de
                            carrière, organisation, QVCT, conseil en stratégie RH.</p>
                        <br>
                        <p>L’équipe pluridisciplinaire s'appuie sur des expertises techniques variées (psychologues,
                            juristes,
                            responsables RH, formateurs…) et peut intervenir sur toute la France.</p>
                        <br>
                        <p>Le maillage de consultants via le <a href="https://www.rrh-groupe.fr" target="_blank"><b
                                    class="bold-underline">groupe RRH</b></a> sur le territoire permet de s’adapter à
                            vos
                            enjeux
                            locaux.</p>
                        <br>
                        <p>Notre objectif : vous accompagner pour réussir tous vos projets.</p>
                    </div>
                    <a class="btn-transparent" href="/presentation#story" target="_blank">Découvrir EMCI <i
                            class="fa-solid fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
import { ref, onMounted } from 'vue';

const points = ref([
    {
        id: 2,
        img: '/images/pages/accueil/icon-point.png',
        title: '30 bis rue du Mail  76100 Rouen',
        coordY: '65',
        coordX: '270',
        link: "",
    },
    {
        id: 3,
        img: '/images/pages/accueil/icon-point.png',
        title: '39 avenue Hoche 51100 Reims',
        coordY: '95',
        coordX: '410',
        link: "",
    },
    {
        id: 4,
        img: '/images/pages/accueil/icon-point.png',
        title: '45 rue Liancourt, 75014 Paris',
        coordY: '120',
        coordX: '335',
        link: "",
    },
    {
        id: 5,
        img: '/images/pages/accueil/icon-point.png',
        title: '53 All. de la Robertsau, 67000 Strasbourg',
        coordY: '120',
        coordX: '585',
        link: "",
    },
    {
        id: 6,
        img: '/images/pages/accueil/icon-point.png',
        title: '8 rue de Rieux  44000 Nantes',
        coordY: '210',
        coordX: '154',
        link: "",
    },
    {
        id: 8,
        img: '/images/pages/accueil/icon-point.png',
        title: '21 rue du Commandant Fuzier   69003 Lyon',
        coordY: '335',
        coordX: '450',
        link: "",
    },
    {
        id: 9,
        img: '/images/pages/accueil/icon-point.png',
        title: 'Rte de la Bouvarde, 74370 Annecy',
        coordY: '320',
        coordX: '530',
        link: "",
    },
    {
        id: 10,
        img: '/images/pages/accueil/icon-point.png',
        title: '43-45 rue d’Armagnac, 33800 Bordeaux',
        coordY: '410',
        coordX: '184',
        link: "",
    },
    {
        id: 11,
        img: '/images/pages/accueil/icon-point.png',
        title: '1 Rue Pierre Rectoran, 64100 Bayonne',
        coordY: '495',
        coordX: '140',
        link: "",
    },
    {
        id: 12,
        img: '/images/pages/accueil/icon-point.png',
        title: 'Toulouse',
        coordY: '495',
        coordX: '287',
        link: "",
    },
    {
        id: 13,
        img: '/images/pages/accueil/icon-point.png',
        title: 'Rue Maryam Mirzakhani, 34000 Montpellier',
        coordY: '495',
        coordX: '407',
        link: "",
    },
    {
        id: 14,
        img: '/images/pages/accueil/icon-point.png',
        title: '20 Allée Turcat Méry  13008 Marseille',
        coordY: '505',
        coordX: '482',
        link: "",
    },

    {
        id: 17,
        img: '/images/pages/accueil/icon-point.png',
        title: 'Montauban',
        coordY: '425',
        coordX: '287',
        link: "",
    },
    {
        id: 18,
        img: '/images/pages/accueil/icon-point.png',
        title: 'Figeac',
        coordY: '462',
        coordX: '227',
        link: "",
    },
    {
        id: 19,
        img: '/images/pages/accueil/icon-point.png',
        title: 'Cahors',
        coordY: '498',
        coordX: '197',
        link: "",
    },
    {
        id: 20,
        img: '/images/pages/accueil/icon-point.png',
        title: 'Blagnac',
        coordY: '535',
        coordX: '197',
        link: "",
    },
    {
        id: 21,
        img: '/images/pages/accueil/icon-point.png',
        title: 'Albi',
        coordY: '498',
        coordX: '337',
        link: "",
    },
    {
        id: 22,
        img: '/images/pages/accueil/icon-point.png',
        title: 'Quint-Fonsegrives',
        coordY: '535',
        coordX: '337',
        link: "",
    }

]);
const displayFromPoint17 = ref(false);

function toggleDisplayFromPoint17(event) {
    if (event.type === 'mouseover') {
        displayFromPoint17.value = true;
    } else if (event.type === 'mouseleave') {
        displayFromPoint17.value = false;
    }
}

onMounted(() => {
    points.value.forEach(point => {
        const id = point.id;
        const content = point.title;
        tippy(`#point-${id}`, {
            content: content,
        });
    });
    const point12Element = document.getElementById('point-12');
    if (point12Element) {
        point12Element.addEventListener('mouseover', toggleDisplayFromPoint17);
        point12Element.addEventListener('mouseleave', toggleDisplayFromPoint17);
    }
});
</script>
<style scoped>
.bold-underline {
    font-weight: bold;
    text-decoration: underline;
}

.body-text {
    max-height: 65vh;
    overflow: auto;
}

.titre-section {
    color: var(--accent, #25233E);
    text-align: center;
    font-family: Nunito;
    font-size: 3.125rem;
    /*50 px */
    font-style: normal;
    font-weight: 600;
    line-height: 111.5%;
    letter-spacing: -0.825px;
    text-transform: uppercase;
    text-align: left;
}

.section-padding {
    padding: 4rem;
}

.carte {
    z-index: 0;
    min-width: 630px;
    max-width: 630px;
}

.point {
    z-index: 1;
}

.point:hover {
    cursor: pointer;
}


.carteTexte p {
    font-size: 1.125rem;
    font-family: Inter;
}

.btn-transparent {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    transition: all .1s;
}

.btn-transparent:hover {
    color: #fff;
    background: linear-gradient(93deg, #318EC7 9.92%, #E94B54 52.75%, #FAB74B 88.05%);
}

.desktop {
    display: block;
}

.mobile {
    display: none;
}

#point-12 {
    width: 40px !important;
}

.point-12-city-arround {
    background-color: #FFF;
    color: #25233E;
    border-radius: 5px;

}

/* Styles pour les écrans de petite taille (S) */
@media only screen and (max-width: 599px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .section-padding {
        padding: 0rem;
    }

    .carte {
        min-width: 290px;
        max-width: 290px;
        margin-left: -73px;
    }

    .p-relative {
        margin-bottom: -155% !important;
        padding-left: 5rem;
        padding-top: 3rem;
    }

    .carteTexte p {
        font-size: 0.8rem;
        line-height: normal;
    }

    .carteTexte {
        margin-bottom: 4rem;
    }
}

@media only screen and (max-width: 768px) {
    .box-point {
        display: block;
    }

    .liste-ville-emci,
    .hr-xs {
        display: block;
    }

    .columns {
        flex-direction: column;
    }

    .p-relative {
        margin-bottom: 0%;
    }

    .span-background.title {
        white-space: nowrap;
    }
}

@media only screen and (min-width: 650px) and (max-width: 768px) {
    .p-relative[data-v-f8efe21e] {
        margin-bottom: -80%;
    }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
    .p-relative[data-v-f8efe21e] {
        margin-bottom: -70%;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1024px) {
    .carte {
        min-width: 379px;
        max-width: 389px;
        position: absolute;
        left: -4%;
        top: 11%;
    }

    #point-1 {
        top: 47px !important;
        left: 193px !important;
    }

    #point-2 {
        top: 94px !important;
        left: 150px !important;
    }

    #point-3 {
        top: 111px !important;
        left: 221px !important;
    }

    #point-4 {
        top: 125px !important;
        left: 180px !important;
    }

    #point-5 {
        top: 124px !important;
        left: 332px !important;
    }

    #point-6 {
        top: 187px !important;
        left: 61px !important;
    }

    #point-7 {
        top: 268px !important;
        left: 202px !important;
    }

    #point-8 {
        top: 266px !important;
        left: 248px !important;
    }

    #point-9 {
        top: 247px !important;
        left: 301px !important;
    }

    #point-10 {
        top: 310px !important;
        left: 87px !important;

    }

    #point-11 {
        top: 353px !important;
        left: 65px !important;
    }

    #point-12 {
        top: 352px !important;
        left: 146px !important;
        width: 33px !important;
    }

    #point-13 {
        top: 350px !important;
        left: 234px !important;
    }

    #point-14 {
        top: 356px !important;
        left: 264px !important;
    }

    #point-15 {
        top: 381px !important;
        left: 349px !important;
    }

    #point-16 {
        top: 340px !important;
        left: 334px !important;
    }


    #point-1,
    #point-2,
    #point-3,
    #point-4,
    #point-5,
    #point-6,
    #point-7,
    #point-8,
    #point-9,
    #point-10,
    #point-11,
    #point-12,
    #point-13,
    #point-14,
    #point-15,
    #point-16 {
        width: 25px;
    }

    .carteTexte {
        font-size: 0.8rem;
        font-family: Inter;
        line-height: normal;
    }

}

/** ecran 1280 * 1024  */

@media only screen and (min-width: 1088px) and (max-width: 1440px) {
    .add-padding {
        padding: 0 0.1vw;
    }

    .carte-France {
        margin-left: -60px;
    }

    .section-padding {
        padding: 2rem 0.3rem 0.30rem 1rem;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1550px) {

    p,
    .bloctitleText,
    a,
    a span,
    .small_desc {
        font-size: 0.863rem !important;
    }
}
</style>