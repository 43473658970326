<template>
    <Header12 />
    <section class="body">
        <Hero />
    </section>
    <section class="background-bleu effet">
        <Expertises />
    </section>
    <section class="background-clair" id="cartee">
        <EmciFrance />
    </section>
    <section>
        <Compteur />
    </section>
    <section class="background-rose">
        <ListeActualite />
    </section>
    <section class="background-bleu">
        <Equipe />
    </section>
    <section class="background-clair avis">
        <AvisClient :idCategory="75" /> <!-- 76 -->
    </section>
    <section>
        <NousContacter />
    </section>
    <section>
        <Footer :dark_bg="true" />
    </section>
</template>

<script setup>
import Header12 from '~/components/common/Headers/Header-12.vue';
import Hero from '@/components/emci/Homepage/Hero/index.vue';
import Expertises from '@/components/emci/Homepage/Nos-expertises/index.vue';
import EmciFrance from '~/components/emci/Homepage/Carte/index.vue';
import Compteur from '@/components/emci/Homepage/Compteur/index.vue';
import Equipe from '@/components/emci/Homepage/Equipe/index.vue';
import ListeActualite from '@/components/emci/Actualite/listActualite.vue';
import AvisClient from '@/components/emci/AvisClient/index.vue';
import NousContacter from '@/components/emci/NousContacter/index.vue';
import Footer from '@/components/emci/Footer/index.vue'

useHead({
    title: 'EMCI, Evaluation, Management, Conseil et Innovation',
    meta: [
        {
            name: 'description',
            content: 'EMCI accompagne les enjeux des entreprises, des collectivités et facilite les évolutions professionnelles des collaborateurs.'
        }
    ]
})
</script>

<style scoped>
.effet {
    position: relative;
    top: -10px;
    margin-bottom: -10px;
}

.body h1 {
    color: white;
    font-weight: 700;
}

.body h2 {
    color: #8CB648;
    font-weight: 700;
}

.background-clair {
    height: 96vh;
    overflow: hidden;
}

.avis {
    max-height: 70vh;
    ;
}

@media screen and (max-width: 500px) {
    .background-clair {
        height: auto;
    }
}

@media only screen and (max-width: 599px) {
    .body {
        margin-top: -78px !important;
    }
}

@media screen and (min-width: 600px) and (max-width: 769px) {
    .body {
        margin-top: -67px !important;
    }
}


@media screen and (max-width: 768px) {
    .background-clair {
        height: auto !important;
    }
}

@media only screen and (max-width: 320px) {}

@media screen and (min-width: 770px) and (max-width: 1000px) {
    .body {
        margin-top: -90px !important;
    }

    .background-clair {
        height: auto;
    }
}

@media only screen and (min-width : 1001px) and (max-width : 1024px) {
    .background-clair {
        height: auto !important;
    }

    .body {
        margin-top: 115px;
        overflow: unset !important;
    }
}

@media only screen and (min-width : 1025px) and (max-width : 1389px) {
    .background-clair {
        height: 110vh;
        overflow: hidden;
    }

    .body-text {
        max-height: auto;
    }
}

@media only screen and (min-width : 1390px) and (max-width : 1399px) {
    .background-clair {
        height: 110vh;
        overflow: hidden;
    }

    .body-text {
        max-height: auto;
    }

    .add-padding {
        padding: 0 0.5vw;
    }
}



@media only screen and (min-width: 1400px) and (max-width: 1550px) {
    .body {
        margin-top: 85px;
        overflow: unset !important;
    }
}

@media only screen and (min-width: 1550px) {
    .body {
        margin-top: 120px;
        overflow: unset !important;
    }
}

@media (width: 1440px) {
    .background-clair {
        height: 115vh;
        overflow: hidden;
    }
}

@media (width: 2560px) {
    .background-clair {
        height: 63vh;
        overflow: hidden;
    }
}
</style>