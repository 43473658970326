<template>
	<div class="counter-style-two section-padding">
		<div>
			<div class="container">
				<h2 class="titre-section">EMCI
					<span class="span-background">
						en quelques chiffres...
					</span>
				</h2>
				<div class="row justify-content-center">
					<div v-for="item in counterData" :key="item.id" class="col-lg-4 col-sm-6  col-md-12 compteurItem"
						:data-aos="isMobile ? '' : 'fade-up'" :data-aos-duration="isMobile ? 0 : 1200"
						:data-aos-delay="isMobile ? 0 : item.delay">
						<div class="counter-box-four">
							<h2 class="number">
								<!--<img class="img-counter" :src="item.img" :alt="item.title">-->
								<div class="iconNumber" v-html="item.img"></div>
								<span class="timer">
									<span class="chiffre d-flex align-items-center justify-content-center">
										{{ item.value }}
										{{ item.text ? item.text : "" }}
									</span>
								</span>
							</h2>
							<p class="title">{{ item.title }}</p>
						</div>
						<!-- /.counter-box-four -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>

const startCounter = ref(false);
const isMobile = ref(false);
const counterData = reactive([
	{
		id: 1,
		img: '<i class="fa-solid fa-trophy"></i>',
		value: 25,
		title: "années d’expérience",
		text: "",
	},
	{
		id: 2,
		img: '<i class="fa-solid fa-user-group"></i>',
		value: 96,
		title: "de clients satisfaits",
		delay: "100",
		text: "%",
	},
	{
		id: 3,
		img: '<i class="fa-solid fa-layer-group"></i>',
		value: 1382,
		title: "projets réalisés en 2023",
		delay: "200",
		text: "",
	},
]);



onMounted(() => {
	const isMobile = ref(window.innerWidth <= 1000);
	window.addEventListener('resize', () => {
		isMobile.value = window.innerWidth <= 769;
	});
});


</script>

<style scoped>
.titre-section {
	color: var(--accent, #25233E);
	text-align: center;
	font-family: Nunito;
	font-size: 3.125rem;
	/*50 px */
	font-style: normal;
	font-weight: 600;
	line-height: 111.5%;
	letter-spacing: -0.825px;
	text-transform: uppercase;
}

.counter-box-four {
	border-radius: 10px;
}

.chiffre,
.iconNumber {
	font-family: Nunito;
	font-size: 4.8rem;
}

.title {
	font-family: Inter;
	font-size: 1.3125rem;
	font-style: normal;
	font-weight: 600;
}

.section-padding {
	padding: 5rem 0;
}

.compteurItem:nth-child(1) .iconNumber:deep(i) {
	color: #FAB74B;
	transition: all .4s;
}

.compteurItem:nth-child(2) .iconNumber:deep(i) {
	color: #E94B54;
	transition: all .4s;
}

.compteurItem:nth-child(3) .iconNumber:deep(i) {
	color: #318EC7;
	transition: all .4s;
}

.compteurItem:hover .iconNumber:deep(i) {
	color: black;
}

.compteurItem {
	border-radius: 9px;
	margin-top: 2rem;
	padding: 2rem;
	border-radius: 10px;
}

.compteurItem:hover {
	border-image: linear-gradient(93deg, #318EC7 9.92%, #E94B54 52.75%, #FAB74B 88.05%) 30;
	border-width: 2px;
	border-style: solid;
}

h2,
.span-background-blue,
.titre-section,
.titre-section span {
	font-size: 3.5rem !important;
}

@media only screen and (max-width: 599px) {

	.chiffre,
	.iconNumber {
		font-size: 2.5rem;
	}

}

@media only screen and (max-width: 991px) {
	.span-background {
		white-space: nowrap;
	}

	.section-padding {
		padding-bottom: 0;
		padding-top: 3rem;
	}

	.compteurItem {
		margin-top: 0rem;
		padding: 0rem;
	}

	h2,
	.span-background-blue,
	.titre-section,
	.titre-section span {
		font-size: 1.55rem !important;
	}

}

@media only screen and (min-width: 1000px) and (max-width: 1024px) {
	.span-background {
		white-space: nowrap;
	}

	.section-padding {
		padding-bottom: 0;
		padding-top: 3rem;
	}
}
</style>